
.site-layout-background {
  background: #fff;
}

.site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 60vh;
}

.logo {
  float: left;
  color: #EEE;
  font-weight: 600;
  font-size: 1.2rem;
  margin-right: 32px;
}

.loggedInUser {
}
